import { api } from "@/base/utils/request";

export const getTemplateDetail = (id) => {
  return api({
    url: '/admin/approval/tmpl/detail',
    method: 'post',
    data: {id}
  })
}

export const saveTemplateDetail = (data) => {
  return api({
    url: '/admin/approval/tmpl/save',
    method: 'post',
    data
  })
}

export const allOrganizations = (data) => {
  return api({
    url: '/admin/cyc/Organization/OrganizationsTree',
    method: 'post',
    data
  })
}
